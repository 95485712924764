export const getters = {
  transportationDetails: (state) => state.details,
  hasTransportationDetails: (state) =>
    !state.details.isLoading && state.details.value !== null,
  passengers: (state, getters, rootState) =>
    !!rootState.transportation
      ? rootState.transportation.transportBookingContent.passengers
      : null,
  getTravelers: (state) => state.travelers,
};
