<template>
  <v-combobox
    class="search-box"
    dense
    solo
    outlined
    :autofocus="focus"
    clearable
    flat
    :items="items"
    no-filter
    :loading="isLoading"
    :search-input.sync="search"
    color="secondary"
    :value="selectedValue"
    @change="onChange"
    @click:clear="onClear"
    prepend-inner-icon="mdi-map-marker-outline"
    append-icon=""
    :placeholder="$t('transportation.search.palceholder_airport_place')"
    return-object
    :no-data-text="$t('no_location_found')"
    :rules="[(v) => !!v || $t('required')]"
    label=""
    aria-labelledby="SearchComponent"
    aria-selected="false"
    tabindex="0"
  >
    <template v-slot:prepend-item>
      <v-list-item class="item-text" ripple @click="getUserLocation">
        <v-list-item-action>
          <v-icon color="secondary">mdi-near-me</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="item-text">
            {{ $t('current_location') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template slot="item" slot-scope="{ item }">
      <v-list-item-action>
        <v-icon color="secondary">mdi-map-marker-</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="item-text">
          {{ item.text }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-combobox>
</template>

<script>
import GroundTransportationMixins from '../../mixins/GroundTransportationMixins.vue';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
export default {
  mixins: [GroundTransportationMixins, SelectedContextFlightMixins],
  name: 'SearchComponent',
  data() {
    return {
      model: null,
      search: null,
      selectedItemValue: { text: this.selectedValue },
    };
  },
  props: {
    result: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
    selectedValue: { type: String },
    focus: Boolean,
    isFromLocationSearch: { type: Boolean, default: false },
    isToLocationSearch: { type: Boolean, default: false },
  },
  methods: {
    getUserLocation() {
      this.search = '';
      this.$emit('userLocationRequest');
    },
    onClear() {
      this.selectedItemValue = null;
      this.$emit('itemSelected', null);
    },
    onChange(args) {
      if (args) {
        this.selectedItemValue = args;
      } else {
        this.selectedItemValue = {
          text: null,
        };
      }
      this.$emit('itemSelected', this.selectedItemValue.text);
      this.$emit('search', this.selectedItemValue.text);
    },
    addPinnedItemToSearchSuggestions(items) {
      const pinnedItem = {
        item: this.selectedFlightDestination,
        text: this.isFromLocationSearch
          ? this.selectedFlightDestination.name
          : this.selectedFlightDeparture.name,
      };
      return [pinnedItem, ...items];
    },
  },
  watch: {
    search(val, oldVal) {
      if (
        !val ||
        (!!this.selectedItemValue && val === this.selectedItemValue.text)
      ) {
        return;
      }
      this.$emit('search', val);
    },
    model(val, oldVal) {
      if (val !== oldVal && val) {
        this.$emit('input', val);
      }
    },
    selectedValue(val, oldVal) {
      //TO DO: Seach components not updating when back button os clicked on
      if (val !== oldVal && val) {
        this.selectedItemValue.text = val;
      }
    },
  },
  computed: {
    items() {
      let items = [];
      if (!!this.result) {
        items = items.concat(this.result);
      }

      if (this.selectedContextFlight) {
        items = this.addPinnedItemToSearchSuggestions(items);
      }

      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  width: 100%;
}

.item-text {
  font-size: 14px !important;
}
::v-deep .v-select__slot input {
  background-image: none !important;
}
</style>
