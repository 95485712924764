<template>
  <v-combobox
    class="time-picker"
    dense
    solo
    outlined
    :clearable="clearable"
    flat
    :items="items"
    :value="value"
    no-filter
    color="secondary"
    append-icon=""
    :placeholder="inputPlaceholder"
    @change="$emit('input', $event)"
    return-object
    :rules="[(v) => !!v || $t('required')]"
    :label="label"
    aria-labelledby="SearchComponent"
    aria-selected="false"
    :menu-props="{
      maxWidth: 160,
      contentClass: 'time-picker-menu-content',
    }"
    tabindex="0"
  >
    <template slot="item" slot-scope="{ item }">
      <v-list-item-content class="time-picker-item pa-0">
        <v-list-item-title class="item-text">
          {{ item }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: 'TimePicker',
  props: {
    value: {
      type: String,
    },
    inputPlaceholder: {
      type: String,
      default: 'Enter Time',
    },
    label: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: '12h',
      validator: function(value) {
        return ['12h', '24h'].includes(value);
      },
    },
    interval: {
      type: String,
      default: '1h',
      validator: function(value) {
        return ['15m', '30m', '1h'].includes(value);
      },
    },
  },
  computed: {
    items() {
      const items = [];
      let time = this.format === '12h' ? 0 : 0.25;

      const intervalMapping = {
        '15m': 0.25,
        '30m': 0.5,
        '1h': 1,
      };

      const intervalValue = intervalMapping[this.interval];

      while (time < 24) {
        const hour = Math.floor(time);
        const minute = (time % 1) * 60;

        let timeString = '';

        if (this.format === '12h') {
          const isPM = hour >= 12;
          const hour12 = hour % 12 || 12;
          const minuteString = minute === 0 ? '00' : String(minute);
          timeString = `${hour12}:${minuteString} ${isPM ? 'PM' : 'AM'}`;
        } else {
          const hourString = String(hour).padStart(2, '0');
          const minuteString = String(minute).padStart(2, '0');
          timeString = `${hourString}:${minuteString}`;
        }

        items.push(timeString);
        time += intervalValue;
      }

      return items;
    },
  },
};
</script>

<style lang="scss">
.time-picker-menu-content {
  margin-top: 8px;
  border-radius: 8px !important;

  .v-list-item {
    min-height: 30px !important;
  }
}

.time-picker-item {
  min-height: 26px !important;
}
</style>
