<template>
  <v-container class="py-0 px-3 px-md-0">
    <v-row>
      <template v-for="(ride, index) in rideList">
        <v-col :key="index" cols="12" md="4" data-cy="result-item">
          <div class="fill-height">
            <router-link
              class="text-decoration-none fill-height"
              :to="{ name: 'transportation-details', params: { id: ride.id } }"
            >
              <ResultItemComponent class="fill-height" :rideInfo="ride" />
            </router-link>
          </div>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>
<script>
import ResultItemComponent from './ResultItemComponent.vue';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
export default {
  name: 'ResultListComponent',
  components: { ResultItemComponent },
  mixins: [GoogleTagManagerMixins],
  props: {
    rideList: { type: Array, default: () => [] },
    searchLocationQuery: String,
  },
};
</script>
<style lang="scss" scoped>
.text-decoration-none {
  text-decoration: none;
}
</style>
