<template>
  <ContentDisplay />
</template>

<script>
import GroundTransportationMixins from './mixins/GroundTransportationMixins';
import ContentDisplay from './features/ContentDisplay';

export default {
  components: {
    ContentDisplay,
  },
  mixins: [GroundTransportationMixins],

  data() {
    return {
      headerTitle: this.$t('transportation.header_title'),
      headerSubtitle: this.$t('transportation.header_subtitle'),
    };
  },
};
</script>
