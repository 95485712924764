<template>
  <v-card flat class="item-card px-3">
    <v-row no-gutters align="center" class="fill-height">
      <v-col class="pr-3 pt-3" cols="4">
        <v-img
          alt="rideImg"
          contain
          v-on:error="onImgError"
          :src="rideImgUrl"
          max-height="40px"
          max-width="100px"
          position="center"
          class="mx-auto"
        />

        <v-row justify="space-around" no-gutters class="pt-2">
          <v-col cols="auto">
            <v-row align="center">
              <v-icon dense color="var(--v-grey7-base)">
                mdi-account-outline
              </v-icon>
              <span
                :class="[
                  $vuetify.breakpoint.mdAndUp ? 'text-size' : 'text-mobile',
                ]"
              >
                {{ rideInfo.maxPassengers }}
              </span>
            </v-row>
          </v-col>
          <v-col cols="auto">
            <v-row align="center">
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M16.6669 5.8335H3.3336C2.41312 5.8335 1.66693 6.57969 1.66693 7.50016V15.8335C1.66693 16.754 2.41312 17.5002 3.3336 17.5002H16.6669C17.5874 17.5002 18.3336 16.754 18.3336 15.8335V7.50016C18.3336 6.57969 17.5874 5.8335 16.6669 5.8335Z"
                    stroke="#586271"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.3336 17.5V4.16667C13.3336 3.72464 13.158 3.30072 12.8454 2.98816C12.5329 2.67559 12.109 2.5 11.6669 2.5H8.3336C7.89157 2.5 7.46765 2.67559 7.15509 2.98816C6.84253 3.30072 6.66693 3.72464 6.66693 4.16667V17.5"
                    stroke="#586271"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <path
                      d="M0.000244141 0H20.0002V20H0.000244141V0Z"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span
                :class="[
                  'baggage',
                  $vuetify.breakpoint.mdAndUp ? 'text-size' : 'text-mobile',
                ]"
              >
                {{ rideInfo.maxBags }}
              </span>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ridetime-wrapper py-2 pl-2" justify="start" no-gutters>
          <v-col cols="auto">
            <span
              :class="[$vuetify.breakpoint.mdAndUp ? 'price' : 'price-mobile']"
            >
              {{ priceInfo.value | currency(priceInfo.baseCurrency) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="7" class="mr-1 details-section">
        <v-container fluid class="fill-height">
          <v-row align="start" justify="start" no-gutters>
            <v-col cols="12" class="my-1">
              <v-row no-gutters class="price text-truncate">
                {{ rideInfo.title }}
              </v-row>
            </v-col>
            <v-col
              cols="12"
              class="my-1"
              min-height="14"
              v-if="isCovidPrepared(rideInfo.subTitle)"
            >
              <v-img
                alt="covidImg"
                contain
                :height="20"
                :src="covidImg"
                position="left"
              />
            </v-col>
            <v-col cols="12" class="my-1">
              <v-row no-gutters>
                <span
                  class="text-truncate"
                  :class="[
                    $vuetify.breakpoint.mdAndUp ? 'text-size' : 'text-mobile',
                  ]"
                >
                  {{ filteredAmenities(rideInfo.subTitle) }}
                </span>
              </v-row>
            </v-col>
            <v-col cols="12" class="my-1">
              <span>
                {{ rideInfo.rideTimeMin }}
                {{ $t('transportation.minutes_ride') }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: 'ResultItemComponent',
  props: {
    rideInfo: { type: Object, default: null },
  },
  data() {
    return {
      covidImg: require('@/assets/covid_prepared.png'),
      no_image: false,
    };
  },
  computed: {
    rideImgUrl() {
      return this.no_image
        ? require('@/assets/placeholder-image.jpg')
        : this.getOriginalImage(this.rideInfo.thumbnail);
    },
    priceInfo() {
      return this.rideInfo.priceStartingAt;
    },
  },
  methods: {
    isCovidPrepared(amenities) {
      if (amenities) {
        return amenities.split(',').includes(this.$t('covid_prepared'));
      }
      return false;
    },
    getOriginalImage(img) {
      if (!img) {
        return;
      }
      return img.split('?fit')[0];
    },
    filteredAmenities(list) {
      if (list) {
        return list
          .split(',')
          .filter((x) => x !== this.$t('covid_prepared'))
          .join();
      }
      return list;
    },
    onImgError(event) {
      this.no_image = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.price {
  font-size: 14px;
  font-weight: 600;
  color: #05090f;
}
.price-mobile {
  font-size: 12px;
  font-weight: 600;
  color: #05090f;
}
.item-card {
  background: #ffffff;
  border: 1px solid #e4e8ef;
  box-sizing: border-box;
  border-radius: 6px;
}
.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-size {
  font-size: 14px;
  color: var(--v-grey7-base);
}
.text-mobile {
  font-size: 12px;
  color: var(--v-grey7-base);
}
.ridetime-wrapper {
  font-size: 14px;
  color: var(--v-grey7-base);
}
.ride-title {
  word-break: break-all;
  text-align-last: left;
}
.count {
  display: inline-block;
  width: 100%;
}
.baggage {
  padding-right: 2px;
}
.ride-image-wrapper {
  height: 40px;
  width: 100px;
}
.details-section {
  text-align: justify;
}
</style>
