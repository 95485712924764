<template>
  <v-container class="mt-6 mt-md-16 info-card-wrapper">
    <v-card class="info-card">
      <v-row>
        <v-col
          cols="12"
          md="5"
          class="px-16 py-12 d-flex flex-column info-card-text-wrapper"
        >
          <span class="info-card-content-title pb-4">
            {{ $t('transportation.content_display.title') }}
          </span>
          <strong class="mt-2 info-card-content-subtitle">{{
            $t('transportation.content_display.subtitle')
          }}</strong>
          <p class="info-card-content-description">
            {{ $t('transportation.content_display.description') }}
          </p>
          <p class="info-card-content-search">
            {{ $t('transportation.content_display.search') }}
          </p>
        </v-col>
        <v-col cols="12" md="7" class="py-0">
          <v-img
            alt="transport"
            class="full-height"
            :height="$vuetify.breakpoint.mdAndUp ? 400 : 280"
            :src="fetchImgSrc"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'content-display',
  computed: {
    fetchImgSrc() {
      if (this.$vuetify.breakpoint.mdOnly) {
        return require('@/assets/transport-desktop.png');
      } else return require('@/assets/transport-tablet.png');
    },
  },
};
</script>

<style lang="scss" scoped>
.full-height {
  height: 100%;
  border-radius: 0;

  @media (min-width: map-get($grid-breakpoints, md)) {
    border-radius: 0 4px 4px 0;
  }
}

.image-card {
  width: 100%;
  height: 100%;
}

.info-card {
  background: var(--v-grey2-base);
  box-shadow: none !important;
  width: 100%;
  border-radius: 0;

  @media (min-width: map-get($grid-breakpoints, md)) {
    border-radius: 4px;
  }

  &-wrapper {
    @media (max-width: map-get($grid-breakpoints, md)) {
      padding: 0;
    }
  }

  &-text-wrapper {
    @media (max-width: map-get($grid-breakpoints, md)) {
      border-top: 1px solid var(--v-grey4-base);
    }
  }

  &-content {
    &-title,
    &-subtitle,
    &-description,
    &-search {
      max-width: 330px;
    }

    &-title {
      @include font-size(24, 130, 600);
      letter-spacing: 0.25px;
      color: var(--v-grey9-base);
    }

    &-subtitle {
      @include font-size(17, 130, 600);
    }

    &-description {
      @include font-size(18, 130);
    }

    &-search {
      @include font-size(18, 130);
      font-style: italic;
    }
  }
}
</style>
