import { render, staticRenderFns } from "./SearchModalComponent.vue?vue&type=template&id=8cef74d6&scoped=true&"
import script from "./SearchModalComponent.vue?vue&type=script&lang=js&"
export * from "./SearchModalComponent.vue?vue&type=script&lang=js&"
import style0 from "./SearchModalComponent.vue?vue&type=style&index=0&id=8cef74d6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cef74d6",
  null
  
)

export default component.exports