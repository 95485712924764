import * as mutations from './mutation-types';
import { RepoFactory } from '@/core/repositories';

export const actions = {
  async loadTransportationDetails({ commit }, { transportationId }) {
    const transportationService = RepoFactory.get('transportation', {
      resourceId: transportationId,
    });
    commit(mutations.TRANSPORTATION_DETAILS_ERROR, { error: null });
    commit(mutations.TRANSPORTATION_DETAILS_LOADING, true);
    try {
      const { data } = await transportationService.getDetails();
      commit(mutations.TRANSPORTATION_DETAILS_LOADED, { transportation: data });
    } catch (error) {
      let usefulError = {
        message: error.toString(),
        code: 500,
      };

      if (!!error.response && !!error.response.data) {
        usefulError.message = error.response.data.errorMessage;
        usefulError.code = error.response.data.errorCode;
      }

      commit(mutations.TRANSPORTATION_DETAILS_ERROR, { error: usefulError });
    } finally {
      commit(mutations.TRANSPORTATION_DETAILS_LOADING, false);
    }
  },

  async loadQuestions({ commit }, { transportationId }) {
    const transportationService = RepoFactory.get('transportation', {
      resourceId: transportationId,
    });
    commit(mutations.TRANSPORTATION_QUESTIONS_ERROR, {
      error: null,
    });
    commit(mutations.TRANSPORTATION_QUESTIONS_LOADING, true);
    try {
      const response = await transportationService.getQuestions();
      commit(mutations.TRANSPORTATION_QUESTIONS_LOADED, {
        questions: response.data,
      });
    } catch (error) {
      commit(mutations.TRANSPORTATION_QUESTIONS_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.TRANSPORTATION_QUESTIONS_LOADING, false);
    }
  },

  answerQuestions({ commit }, { answers }) {
    commit(mutations.TRANSPORTATION_ORDER_QUESTIONS_SET, { answers: answers });
  },

  setTravelers({ commit }, travelers) {
    commit(mutations.ORDER_QUESTIONS_SET_TRAVELERS, travelers);
  },
};
