<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="420"
      content-class="gt-refresh-modal"
    >
      <v-card>
        <v-container class="pt-4">
          <v-row justify="center">
            <v-col cols="auto">
              <v-progress-circular
                indeterminate
                color="primary lighten-1"
                :size="53"
              ></v-progress-circular
            ></v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="auto">
              <span class="title">
                {{ $t('transportation.search.please_wait') }}
              </span>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="auto">
              <p class="px-3 text-center">
                {{ $t('transportation.search.text_refresh_search_results') }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'ResultsRefreshModal',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: this.open,
    };
  },
  watch: {
    open: {
      immediate: true,
      handler(open) {
        this.dialog = open;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.gt-refresh-modal .v-card.v-sheet {
  border-radius: 8px;
}
</style>
