<script>
import TransportationStoreModule from '../store/index.js';
export default {
  name: 'GroundTransportationMixins',
  methods: {
    searchRides({
      startLocation,
      endLocation,
      startTime,
      passengers,
      polling,
      minPrice,
      maxPrice,
      currency,
      vehicleSize,
    }) {
      this.$store.dispatch(`transportation/searchRides`, {
        startLocation,
        endLocation,
        startTime,
        passengers,
        polling,
        minPrice,
        maxPrice,
        currency,
        vehicleSize,
      });
    },
    performLocationFromSearch(location) {
      this.$store.dispatch(
        'transportation/performLocationFromSearch',
        location
      );
    },
    performLocationToSearch(location) {
      this.$store.dispatch('transportation/performLocationToSearch', location);
    },
    performUserFromReverseGeocode({ lat, lng }) {
      this.$store.dispatch('transportation/performUserFromReverseGeocode', {
        lat: lat,
        lng: lng,
      });
    },
    performUserToReverseGeocode({ lat, lng }) {
      this.$store.dispatch('transportation/performUserToReverseGeocode', {
        lat: lat,
        lng: lng,
      });
    },
    clearRides() {
      return this.$store.dispatch('transportation/clearRides');
    },
  },
  computed: {
    isLoadingRides() {
      return !this.hasRides && this.allRides.isLoading;
    },
    hasRides() {
      return this.$store.getters[`transportation/hasRides`];
    },
    allRides() {
      return this.$store.getters['transportation/searchResults'];
    },
    hasErrorRides() {
      return !!this.allRides && this.allRides.error !== null;
    },
    ridesContent() {
      return this.$store.state['transportation'].transportBookingContent;
    },
    ridesError() {
      if (!!this.hasErrorRides) return this.ridesContent.error;
      else return null;
    },
    fromSearch() {
      return this.$store.state['transportation'].fromSearch;
    },
    toSearch() {
      return this.$store.state['transportation'].toSearch;
    },
    hasFromSearchResult() {
      return this.$store.getters['transportation/hasFromSearchResult'];
    },
    hasToSearchResult() {
      return this.$store.getters['transportation/hasToSearchResult'];
    },
    hasFromUserLocationResult() {
      return this.$store.getters['transportation/hasFromUserLocationResult'];
    },
    hasToUserLocationResult() {
      return this.$store.getters['transportation/hasToUserLocationResult'];
    },
    searchTimestamp() {
      return !!this.$store.state['transportation']
        ? this.$store.state['transportation'].searchTimestamp
        : null;
    },
    gtSearchQuery() {
      return this.$store.getters['transportation/gtSearchQuery'];
    },
  },
};
</script>
