var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"item-card px-3",attrs:{"flat":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"pr-3 pt-3",attrs:{"cols":"4"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"alt":"rideImg","contain":"","src":_vm.rideImgUrl,"max-height":"40px","max-width":"100px","position":"center"},on:{"error":_vm.onImgError}}),_c('v-row',{staticClass:"pt-2",attrs:{"justify":"space-around","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{attrs:{"dense":"","color":"var(--v-grey7-base)"}},[_vm._v(" mdi-account-outline ")]),_c('span',{class:[
                _vm.$vuetify.breakpoint.mdAndUp ? 'text-size' : 'text-mobile',
              ]},[_vm._v(" "+_vm._s(_vm.rideInfo.maxPassengers)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center"}},[_c('svg',{attrs:{"width":"20","height":"16","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"clip-path":"url(#clip0)"}},[_c('path',{attrs:{"d":"M16.6669 5.8335H3.3336C2.41312 5.8335 1.66693 6.57969 1.66693 7.50016V15.8335C1.66693 16.754 2.41312 17.5002 3.3336 17.5002H16.6669C17.5874 17.5002 18.3336 16.754 18.3336 15.8335V7.50016C18.3336 6.57969 17.5874 5.8335 16.6669 5.8335Z","stroke":"#586271","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M13.3336 17.5V4.16667C13.3336 3.72464 13.158 3.30072 12.8454 2.98816C12.5329 2.67559 12.109 2.5 11.6669 2.5H8.3336C7.89157 2.5 7.46765 2.67559 7.15509 2.98816C6.84253 3.30072 6.66693 3.72464 6.66693 4.16667V17.5","stroke":"#586271","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0"}},[_c('path',{attrs:{"d":"M0.000244141 0H20.0002V20H0.000244141V0Z","fill":"white"}})])])]),_c('span',{class:[
                'baggage',
                _vm.$vuetify.breakpoint.mdAndUp ? 'text-size' : 'text-mobile',
              ]},[_vm._v(" "+_vm._s(_vm.rideInfo.maxBags)+" ")])])],1)],1),_c('v-row',{staticClass:"ridetime-wrapper py-2 pl-2",attrs:{"justify":"start","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{class:[_vm.$vuetify.breakpoint.mdAndUp ? 'price' : 'price-mobile']},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.priceInfo.value,_vm.priceInfo.baseCurrency))+" ")])])],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{staticClass:"mr-1 details-section",attrs:{"cols":"7"}},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"start","no-gutters":""}},[_c('v-col',{staticClass:"my-1",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"price text-truncate",attrs:{"no-gutters":""}},[_vm._v(" "+_vm._s(_vm.rideInfo.title)+" ")])],1),(_vm.isCovidPrepared(_vm.rideInfo.subTitle))?_c('v-col',{staticClass:"my-1",attrs:{"cols":"12","min-height":"14"}},[_c('v-img',{attrs:{"alt":"covidImg","contain":"","height":20,"src":_vm.covidImg,"position":"left"}})],1):_vm._e(),_c('v-col',{staticClass:"my-1",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('span',{staticClass:"text-truncate",class:[
                  _vm.$vuetify.breakpoint.mdAndUp ? 'text-size' : 'text-mobile',
                ]},[_vm._v(" "+_vm._s(_vm.filteredAmenities(_vm.rideInfo.subTitle))+" ")])])],1),_c('v-col',{staticClass:"my-1",attrs:{"cols":"12"}},[_c('span',[_vm._v(" "+_vm._s(_vm.rideInfo.rideTimeMin)+" "+_vm._s(_vm.$t('transportation.minutes_ride'))+" ")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }