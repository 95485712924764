export const TRANSPORTATION_DETAILS_LOADED = 'TRANSPORTATION_DETAILS_LOADED';
export const TRANSPORTATION_DETAILS_ERROR = 'TRANSPORTATION_DETAILS_ERROR';
export const TRANSPORTATION_DETAILS_LOADING = 'TRANSPORTATION_DETAILS_LOADING';
export const TRANSPORTATION_QUESTIONS_LOADING =
  'TRANSPORTATION_QUESTIONS_LOADING';
export const TRANSPORTATION_QUESTIONS_ERROR = 'TRANSPORTATION_QUESTIONS_ERROR';
export const TRANSPORTATION_QUESTIONS_LOADED =
  'TRANSPORTATION_QUESTIONS_LOADED';
export const TRANSPORTATION_ORDER_QUESTIONS_SET =
  'TRANSPORTATION_ORDER_QUESTIONS_SET';
export const ORDER_QUESTIONS_SET_TRAVELERS = 'ORDER_QUESTIONS_SET_TRAVELERS';
