<template>
  <PopoverModal
    :title="$t('common.price')"
    :triggerText="filterText"
    @submit="onSubmit"
    @clear="onClear"
    :selected="hasUserInput"
  >
    <v-container>
      <v-row align="center" justify="center">
        <v-col>
          <v-range-slider
            @input="onSliderInput"
            :min="min"
            :max="max"
            :value="range"
            :step="step"
            hide-details
            class="align-center"
          />
        </v-col>
      </v-row>
      <v-row class="px-3" align="center" justify="space-around">
        <v-col cols="4">
          <v-container class="pa-0">
            <v-row
              ><span>{{ $t('common.min') }}</span></v-row
            >
            <v-row>
              <v-text-field
                :value="range[0]"
                class="mt-0 pt-0"
                dense
                outlined
                style="width: 60px"
                @keypress="validateForNumbers($event)"
                @change="onMinChange($event)"
              />
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="auto">
          <v-icon>mdi-minus</v-icon>
        </v-col>
        <v-col cols="4">
          <v-container class="pa-0">
            <v-row
              ><span>{{ $t('common.max') }}</span></v-row
            >
            <v-row>
              <v-text-field
                :value="range[1]"
                class="mt-0 pt-0"
                dense
                outlined
                style="width: 60px"
                @keypress="validateForNumbers($event)"
                @change="onMaxChange($event)"
              />
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="2">
          <span>{{ currencyText }}</span>
        </v-col>
      </v-row>
    </v-container>
  </PopoverModal>
</template>

<script>
import PopoverModal from '@/core/components/PopoverModal.vue';
import {
  advancedCurrencyFilter,
  currencyTextFormatter,
} from '@/core/filters/currency';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
export default {
  name: 'PriceRangePopoverModal',
  components: {
    PopoverModal,
  },
  mixins: [GoogleTagManagerMixins],
  props: {
    min: {
      default: 0,
      type: Number,
    },
    max: {
      default: 500,
      type: Number,
    },
    baseCurrency: {
      default: 'USD',
      type: String,
    },
  },
  data() {
    return {
      minModel: this.min,
      maxModel: this.max,
      slider: 40,
      range: [this.min, this.max],
      step: 10,
      hasUserInput:
        this.$route.query?.['max-price'] && this.$route.query?.['min-price']
          ? true
          : false,
    };
  },
  computed: {
    currencyText() {
      return currencyTextFormatter(this.baseCurrency);
    },
    maxValue() {
      return this.range[1];
    },
    minValue() {
      return this.range[0];
    },
    hasPriceRangeFilter() {
      return this.range;
    },
    filterText() {
      return this.hasUserInput ||
        (this.hasUserInput &&
          this.$route.query?.['max-price'] &&
          this.$route.query?.['min-price'])
        ? this.$t('common.price_per_day', { price: this.rangeText })
        : this.$t('common.price_range');
    },
    rangeText() {
      const filterOptions = {
        style: 'currency',
        currency: this.baseCurrency,
        maximumSignificantDigits: 3,
      };
      const minText = advancedCurrencyFilter(this.minValue, filterOptions);
      const maxText = advancedCurrencyFilter(this.maxValue, filterOptions);
      return `${minText} - ${maxText}`;
    },
  },
  methods: {
    onSliderInput(arg) {
      this.range = [arg[0], arg[1]];
    },
    onSubmit() {
      this.pushFilterAnalytics(
        this.$route.path,
        'price',
        this.range,
        this.rangeText
      );
      this.$emit('submit', this.range);
      this.hasUserInput = true;
    },
    onClear() {
      this.minModel = 0;
      this.maxModel = 500;
      this.range = [0, 500];
      this.hasUserInput = false;
      this.$emit('submit', this.range);
    },
    onMaxChange(inputNumber) {
      let newVal = this.parseNumber(inputNumber);
      if (inputNumber <= this.minModel) {
        newVal = this.minModel + 1;
      }

      this.$set(this.range, 1, newVal);
    },
    onMinChange(inputNumber) {
      let newVal = this.parseNumber(inputNumber);
      if (inputNumber >= this.maxModel) {
        newVal = this.maxModel - 1;
      }

      this.$set(this.range, 0, newVal);
    },
    validateForNumbers($event) {
      var charCode = $event.which ? $event.which : $event.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        $event.preventDefault();
        return false;
      }

      return true;
    },
    parseNumber(value, defaultValue) {
      let inputNumber = parseInt(value, 10);

      if (isNaN(inputNumber)) {
        inputNumber = limit;
      }
      return inputNumber;
    },
  },
};
</script>

<style lang="scss" scoped></style>
