<template>
  <PopoverModal
    :title="$t('common.vehicle_types')"
    :triggerText="filterText"
    @submit="onSubmit"
    @clear="onClear"
    :selected="hasUserInput"
  >
    <v-container
      class="vehicleFilter pa-0"
      :style="
        this.$vuetify.breakpoint.smAndDown
          ? 'max-width: auto;'
          : 'max-width: 335px;'
      "
    >
      <v-divider v-if="this.$vuetify.breakpoint.smAndDown" />
      <v-list dense flat>
        <v-list-item-group multiple>
          <template v-for="(option, i) in itemList">
            <label
              :for="option.id"
              :key="i"
              @click.capture.stop="toggleSelect(option.id)"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="item-label">
                    {{ option.label }}
                    <span class="d-block sub-label" v-if="option.sublabel">
                      {{ option.sublabel }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                    :id="option.id"
                    v-model="currentSelectedOptions"
                    name="currentSelectedOptions"
                    color="primary"
                    :value="option.id"
                    @click="toggleSelect(option.id)"
                  />
                </v-list-item-action>
              </v-list-item>
            </label>
            <v-divider :key="'divider' + i" class="mt-2 pb-2" />
          </template>
        </v-list-item-group>
      </v-list>
    </v-container>
  </PopoverModal>
</template>

<script>
import PopoverModal from '@/core/components/PopoverModal.vue';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
export default {
  name: 'VehicleTypesPopoverModal',
  components: {
    PopoverModal,
  },
  mixins: [GoogleTagManagerMixins],
  props: ['vehicleSize'],
  data() {
    return {
      currentSelectedOptions: this.vehicleSize || [],
      hasUserInput: this.$route.query?.['vehicle-size'] ? true : false,
      itemList: [
        {
          id: 'small',
          label: this.$t('common.small'),
          sublabel: this.$t('common.range_passengers', { range: '1~3' }),
        },
        {
          id: 'medium',
          label: this.$t('common.medium'),
          sublabel: this.$t('common.range_passengers', { range: '4~7' }),
        },
        {
          id: 'large',
          label: this.$t('common.large'),
          sublabel: this.$t('common.more_than_passengers', { count: '8' }),
        },
        {
          id: 'shared',
          label: this.$t('common.shared_ride'),
          sublabel: this.$t('common.airport_shuttles_bus_trains'),
        },
      ],
    };
  },
  computed: {
    filterText() {
      return this.currentSelectedOptions.toString().length > 0
        ? this.$t('common.vehicle_type', {
            type: this.currentSelectedOptions.toString(),
          })
        : this.$t('common.vehicle_types');
    },
  },
  watch: {
    ['$i18n.locale'](newValue, oldValue) {
      this.itemList = [
        {
          id: 'small',
          label: this.$t('common.small'),
          sublabel: this.$t('common.range_passengers', { range: '1~3' }),
        },
        {
          id: 'medium',
          label: this.$t('common.medium'),
          sublabel: this.$t('common.range_passengers', { range: '4~7' }),
        },
        {
          id: 'large',
          label: this.$t('common.large'),
          sublabel: this.$t('common.more_than_passengers', { count: '8' }),
        },
        {
          id: 'shared',
          label: this.$t('common.shared_ride'),
          sublabel: this.$t('common.airport_shuttles_bus_trains'),
        },
      ];
    },
  },
  methods: {
    toggleSelect(id) {
      if (this.currentSelectedOptions.includes(id)) {
        this.currentSelectedOptions = this.currentSelectedOptions.filter(
          (el) => el !== id
        );
      } else {
        this.currentSelectedOptions = [...this.currentSelectedOptions, id];
      }
    },
    onSubmit() {
      this.pushFilterAnalytics(
        this.$route.path,
        'vehicle-type',
        this.currentSelectedOptions
      );
      this.$emit('submit', this.currentSelectedOptions);
      this.hasUserInput =
        this.currentSelectedOptions.toString().length > 0 ? true : false;
    },
    onClear() {
      this.currentSelectedOptions = [];
      this.hasUserInput = false;
      this.$emit('submit', this.currentSelectedOptions);
    },
  },
};
</script>

<style lang="scss" scoped>
.vehicleFilter .v-list-item__action .v-input--checkbox {
  position: absolute;
  top: 8px;
}

.vehicleFilter .v-list {
  width: 100%;
}

::v-deep .v-list-item__title.item-label {
  @include font-size(16, 24);
  color: #05090f;
}

::v-deep .sub-label {
  @include font-size(14, 20);
  color: var(--v-grey7-base);
  white-space: initial;
}
</style>
