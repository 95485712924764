<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <vue-headful :title="pageTitle" />
        <template v-if="displayHeader">
          <TransportationHeader
            @submit="loadSearchContent"
            @click="toggleModal"
            :selected="searchParams"
          />
        </template>

        <SearchModalComponent
          :modalView="modalState"
          @close="toggleModal"
          :selected="searchParams"
        />

        <ResultsRefreshModal :open="openRefreshModal" />

        <!-- TODO do we use this timer? -->
        <TimeoutWarningModal
          :open="openWarningModel"
          @click="onBookRide"
          :timestamp="searchTimestamp"
          @close="openWarningModel = false"
        />
        <!-- TODO end -->

        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TransportationHeader from './features/TransportationHeader';
import SearchModalComponent from './features/Search/SearchModalComponent';
import GroundTransportationMixins from './mixins/GroundTransportationMixins';
import moment from 'moment';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import ResultsRefreshModal from './features/Results/ResultsRefreshModal';
import TimeoutWarningModal from './features/TimeoutWarningModal';
import vueHeadful from 'vue-headful';
import FlightRedirectMixins from '@/modules/flights/mixins/FlightRedirectMixins';
import * as Sentry from '@sentry/vue';

export default {
  name: 'TransportationPage',
  mixins: [
    GroundTransportationMixins,
    GoogleTagManagerMixins,
    FlightRedirectMixins,
  ],

  components: {
    ResultsRefreshModal,
    TimeoutWarningModal,
    vueHeadful,
    TransportationHeader,
    SearchModalComponent,
  },

  props: {
    passengers: Number,
    startLocation: String,
    endLocation: String,
    startTime: String,
    polling: Boolean,
    minPrice: Number,
    maxPrice: Number,
    currency: String,
    vehicleSize: [String, Array],
  },

  data() {
    return {
      modalState: false,
      resetTimeoutId: null,
      warningTimeoutId: null,
      openWarningModel: false,
      openRefreshModal: false,
      refreshTimeoutDuration: {
        m: 15,
      },
      warningTimeoutDuration: {
        m: 10,
      },
    };
  },

  created() {
    Sentry.setTag('module', 'transportation');
  },

  watch: {
    searchTimestamp(searchTimestamp, oldVal) {
      if (searchTimestamp === oldVal || searchTimestamp == null) return;
      this.clearTimers();
      this.setTimers(searchTimestamp);
    },

    hasRides(hasRides) {
      if (this.openRefreshModal && hasRides) {
        this.openRefreshModal = false;
        this.openWarningModel = false;
      }
    },

    hasErrorRides(val) {
      if (!!val) {
        this.redirectToErrorPage();
      }
    },
  },

  computed: {
    isShowingDetails() {
      return 'transportation-details' === this.$route.name;
    },

    searchParams() {
      return {
        passengers: this.passengers,
        startLocation: this.startLocation,
        endLocation: this.endLocation,
        startTime: this.startTime,
        polling: this.polling,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        currency: this.currency,
        vehicleSize: this.vehicleSize,
      };
    },

    partnerName() {
      return this.$store.state.configs && this.$store.state.configs.title
        ? this.$store.state.configs.title
        : '';
    },

    pageTitle() {
      return this.$t('transportation.page_title', {
        partnerName: this.partnerName,
      });
    },

    displayHeader() {
      return (
        this.$route.name == 'transportationHome' ||
        this.$route.name == 'transportationSearchResults'
      );
    },
  },

  methods: {
    onBookRide() {
      this.openWarningModel = false;
      if (this.$route.name === 'transportation-details')
        this.$router.push({ name: 'transportation-checkout' });
    },

    toggleModal() {
      this.modalState = !this.modalState;
    },

    redirectToErrorPage() {
      const notFoundError = this.ridesError && this.ridesError.status >= 400;

      const internalServerError =
        this.ridesError && this.ridesError.status >= 500;

      if (internalServerError) this.$router.replace('/500');
      else if (notFoundError) this.$router.replace('/404');
    },

    loadSearchContent(payload) {
      const {
        startLocation,
        endLocation,
        startTime,
        passengers,
        polling,
        minPrice,
        maxPrice,
        currency,
        vehicleSize,
      } = payload;
      this.searchRides({
        startLocation,
        endLocation,
        startTime,
        passengers,
        polling,
        minPrice,
        maxPrice,
        currency,
        vehicleSize,
      });
    },

    async refreshResults() {
      this.openRefreshModal = true;
      this.openWarningModel = false;
      let refreshCount = this.$route.query['refreshCount'] || 0;
      this.pushTransportationResultTimeoutAnalytics(
        this.$route.name,
        'timeout'
      );
      await this.clearRides();
      this.$router.replace({
        name: 'transportationSearchResults',
        query: { ...this.gtSearchQuery, refreshCount: refreshCount + 1 },
      });
    },

    setTimers(searchTimestamp) {
      this.setRefreshTimer(searchTimestamp);
      this.setWarningTimer(searchTimestamp);
    },

    setRefreshTimer(searchTimestamp) {
      const diffDurationTime = this.calculateEndTime(
        searchTimestamp,
        this.refreshTimeoutDuration
      );

      this.resetTimeoutId = setTimeout(() => {
        this.refreshResults();
      }, diffDurationTime);
    },

    setWarningTimer(searchTimestamp) {
      const diffDurationTime = this.calculateEndTime(
        searchTimestamp,
        this.warningTimeoutDuration
      );

      this.warningTimeoutId = setTimeout(() => {
        this.showWarningModal();
      }, diffDurationTime);
    },

    showWarningModal() {
      if (this.isShowingDetails) {
        this.openWarningModel = true;
      }
    },

    calculateEndTime(timestamp, duration) {
      const startTime = moment(timestamp);
      const nowTime = moment();
      const finishTime = startTime.add(duration);
      return finishTime.diff(nowTime, 'ms');
    },

    clearRefreshTimer() {
      if (this.resetTimeoutId) {
        clearTimeout(this.resetTimeoutId);
      }
    },

    clearWarningModal() {
      if (this.warningTimeoutId) {
        clearTimeout(this.warningTimeoutId);
      }
    },

    clearTimers() {
      this.clearRefreshTimer();
      this.clearWarningModal();
    },
  },

  beforeDestroy() {
    this.clearTimers();
  },
};
</script>
