<template>
  <v-card
    width="100%"
    outlined
    class="card-wrapper py-4"
    color="#F2F4F7"
    data-cy="transportation-header-component"
  >
    <v-container
      class="px-0"
      :class="{
        'py-0': $vuetify.breakpoint.smAndDown,
      }"
    >
      <template v-if="!isMobile">
        <v-container class="pa-0">
          <v-row
            class="nav-text pb-1"
            :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
            justify="start"
            align="center"
          >
            <v-col
              @click="goToHomepage"
              class="nav-icon nav-text-home pr-0"
              color="secondary"
              cols="auto"
            >
              <span>{{ $t('homepage_text') }}</span>
            </v-col>
            <v-col cols="auto" class="px-2">
              <span>/</span>
            </v-col>
            <v-col cols="auto" class="pl-0">
              <span>{{ $t('transportation_text') }}</span>
            </v-col>
          </v-row>

          <v-row class="header-title mt-0 pb-4">
            <v-col cols="12" class="py-0">
              {{ $t('transportation.header_title') }}
            </v-col>
          </v-row>
        </v-container>
      </template>

      <v-row
        align="center"
        :no-gutters="$vuetify.breakpoint.mdAndUp"
        :justify="isMobile ? 'center' : 'start'"
      >
        <template v-if="isMobile">
          <v-col cols="auto" class="px-2">
            <v-img
              :alt="$t('transportation.home_navigation_icon')"
              @click="goToHomepage"
              class="nav-icon ma-auto"
              width="24"
              height="24"
              src="@/assets/nav-icon.svg"
            />
          </v-col>
        </template>

        <v-col :cols="setColsBreackpoints" :class="{ 'py-2': isMobile }">
          <template v-if="isMobile">
            <div class="search-trigger" @click="$emit('click')">
              <v-text-field
                @click="$emit('click')"
                class="truncate search-trigger"
                :value="searchResultText"
                disabled
                outlined
                rounded
                dense
                label=""
              >
                <template v-slot:append>
                  <v-img
                    :alt="$t('transportation.search_button')"
                    class="nav-icon ma-auto"
                    width="36"
                    height="36"
                    src="@/assets/search-button.svg"
                  />
                </template>
              </v-text-field>
            </div>
          </template>

          <template v-else>
            <SearchContainer :selected="selected" @submitForm="handleSubmit" />
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import SearchContainer from './Search/SearchContainer';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';

export default {
  name: 'TransportationHeader',
  components: { SearchContainer },
  mixins: [SelectedContextFlightMixins],

  props: {
    selected: Object,
  },

  data() {
    return {
      menu: false,
    };
  },

  methods: {
    goToHomepage() {
      if (this.selectedContextFlight) {
        this.$router.push({ name: 'concierge' });
      } else {
        this.$router.push({ name: 'concierge-discover' });
      }
    },

    handleSubmit(params) {
      this.$emit('submit', params);
    },
  },

  computed: {
    setColsBreackpoints() {
      let cols = 'auto';
      if (this.$vuetify.breakpoint.smOnly) {
        cols = '10';
      } else if (this.$vuetify.breakpoint.mdOnly) {
        cols = '9';
      } else if (this.$vuetify.breakpoint.lgOnly) {
        cols = '12';
      } else if (this.$vuetify.breakpoint.xlOnly) {
        cols = 'auto';
      } else if (this.$vuetify.breakpoint.xsOnly) {
        cols = '10';
      }
      return cols;
    },

    searchResultText() {
      if (this.$route.name === 'transportationHome') {
        return this.$t('transportation.header_title');
      } else {
        return this.$t('transportation.search_result_text', {
          startLocation: this.selected.startLocation,
          endLocation: this.selected.endLocation,
          startTime: this.selected.startTime,
          passengers: this.selected.passengers,
        });
      }
    },

    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-icon {
  cursor: pointer;
}

.nav-text {
  color: var(--v-grey7-base);
  @include font-size(14, 130, 400);

  &-home {
    color: var(--v-primary-base);
  }
}

.header-title {
  @include font-size(30, 150, 300);
}

::v-deep
  .search-trigger
  .v-text-field.v-text-field--enclosed
  .v-text-field__details {
  display: none !important;
}

::v-deep .search-trigger .v-input__slot {
  margin: 0;
}

::v-deep .search-trigger fieldset {
  background: #fff;
  border: 1px solid #cfd5e0;
}

::v-deep
  .search-trigger
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  padding: 0 6px;
}

::v-deep .search-trigger .v-input__append-inner {
  margin-bottom: 4px;
  margin-top: 4px !important;
}

::v-deep .v-text-field__slot input {
  @include font-size(16, 150);
  display: flex;
  align-items: center;
  color: #05090f;
}

::v-deep
  .search-trigger
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  padding: 0 4px 0 16px;
}

.trigger {
  cursor: pointer;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-wrapper {
  background-color: var(--v-grey1-base);

  @media (max-width: map-get($grid-breakpoints, lg)) {
    border-radius: 0;
  }
}
</style>
