<template>
  <v-container class="pa-0 pt-md-5">
    <v-form @submit.prevent="handleSubmit" ref="form">
      <v-row :no-gutters="$vuetify.breakpoint.mdAndDown">
        <v-col
          cols="12"
          md="auto"
          class="py-0 transportation-input-to-from"
          id="SearchComponent"
        >
          <span class="transportation-label">{{ $t('from') }}</span>
          <SearchComponent
            data-cy="transportation-from-text"
            v-bind="fromResult"
            class="transportation-input"
            :focus="true"
            @search="searchFrom"
            @userLocationRequest="getUserFromLocation"
            @itemSelected="fromItemSelected"
            isFromLocationSearch
          />
        </v-col>
        <v-col
          cols="12"
          md="auto"
          class="d-flex justify-end justify-md-center align-center pa-0"
        >
          <v-icon @click="swapLocation" tabindex="0">
            <template v-if="$vuetify.breakpoint.lgAndUp">
              mdi-swap-horizontal
            </template>

            <template v-else>
              mdi-swap-vertical
            </template>
          </v-icon>
        </v-col>

        <v-col cols="12" md="auto" class="py-0 transportation-input-to-from">
          <span class="transportation-label">{{ $t('to') }}</span>
          <SearchComponent
            data-cy="transportation-to-text"
            class="transportation-input"
            v-bind="toResult"
            @search="searchTo"
            @userLocationRequest="getUserToLocation"
            @itemSelected="toItemSelected"
            isToLocationSearch
          />
        </v-col>

        <v-col cols="12" md="auto" class="py-0 transportation-input-picker">
          <DateTimeSelector
            @input="onDateChange"
            labelClass="transportation-label"
            inputClass="transportation-input"
            inputHeight="44"
            :value="selectedDate"
            :allowPastDates="false"
            :allow-time-selection="false"
            :time-tab="false"
            :dateTitle="$t('pickup_date')"
            :input-placeholder="$t('select_date')"
            :hasTitle="true"
            date-format="DD-MMM-YYYY"
            :with-mobile-dialog="false"
          />
        </v-col>

        <v-col cols="12" md="auto" class="py-0 transportation-input-picker">
          <span class="transportation-label">{{ $t('pickup_time') }}</span>
          <TimePicker
            @input="onTimeChange"
            :value="selectedTime"
            :input-placeholder="$t('select_time')"
            class="transportation-input"
            interval="15m"
          />
        </v-col>
      </v-row>

      <v-row :no-gutters="$vuetify.breakpoint.mdAndDown">
        <v-col class="py-0 mb-7 mb-md-0" cols="12" md="3">
          <QuantityField
            data-cy="transportation-travelers-count-field"
            colored
            :title="$t('transportation.search.travelers')"
            :subTitle="$t('transportation.search.maximum_allowed')"
            v-model.number="travelers"
            :max="50"
            :min="1"
          />
        </v-col>
        <v-col
          cols="12"
          md="auto"
          class="py-0 d-flex align-center text-capitalize search-button"
        >
          <v-btn
            data-cy="transportation-submit-button"
            color="primary"
            type="submit"
            dense
            block
            :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
            height="44"
            depressed
          >
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <v-icon>mdi-magnify</v-icon>
            </template>

            {{ $t('search') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import moment from 'moment';
import SearchComponent from './SearchComponent.vue';
import DateTimeSelector from '@/core/components/DateTimeSelector.vue';
import QuantityField from '@/core/components/QuantityField.vue';
import GroundTransportationMixins from '../../mixins/GroundTransportationMixins.vue';
import ConfigsMixins from '@/core/mixins/ConfigsMixins.vue';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import TimePicker from '@/core/components/TimePicker';

export default {
  name: 'SearchContainer',
  components: { TimePicker, SearchComponent, QuantityField, DateTimeSelector },
  mixins: [GroundTransportationMixins, ConfigsMixins, GoogleTagManagerMixins],
  props: {
    selected: Object,
  },

  data() {
    return {
      timer: null,
      fromLocation: this.selected.startLocation || null,
      toLocation: this.selected.endLocation || null,
      date: this.selected.startTime || null,
      time: this.selected.startTime || null,
      travelers: this.selected.passengers || 1,
      priceRange: {
        min: this.selected.minPrice || 1,
        max: this.selected.maxPrice || 10000,
      },
      vehicle: this.selected.vehicleSize || null,
    };
  },

  methods: {
    getUserFromLocation() {
      this.$getLocation({ enableHighAccuracy: true }).then(
        (coordinates) => {
          this.performUserFromReverseGeocode({
            lat: coordinates.lat,
            lng: coordinates.lng,
          });
        },
        (rejected) => {
          //Just to catch the rejection so chrome console doesn't complain
        }
      );
    },

    getUserToLocation(lat, lng) {
      this.$getLocation({ enableHighAccuracy: true }).then(
        (coordinates) => {
          this.performUserToReverseGeocode({
            lat: coordinates.lat,
            lng: coordinates.lng,
          });
        },
        (rejected) => {
          //Just to catch the rejection so chrome console doesn't complain
        }
      );
    },

    searchFrom(val) {
      clearTimeout(this.timer);

      // delay new call 400ms
      this.timer = setTimeout(() => {
        this.performLocationFromSearch(val);
      }, 400);
    },

    searchTo(val) {
      clearTimeout(this.timer);

      // delay new call 400ms
      this.timer = setTimeout(() => {
        this.performLocationToSearch(val);
      }, 400);
    },

    fromItemSelected(fromValue) {
      this.fromLocation = fromValue;
    },

    toItemSelected(toValue) {
      this.toLocation = toValue;
    },

    onDateChange(date) {
      if (!!date) {
        this.date = moment(date).format('YYYY-MM-DD');
      }
    },

    onTimeChange(time) {
      if (!!time) {
        this.time = this.convertTime(time);
      }
    },

    convertTime(timeString) {
      return moment(timeString, ['h:mm A']).format('HH:mm');
    },

    reverseConvertTime(timeString) {
      return moment(timeString, ['HH:mm']).format('h:mm A');
    },

    convertUserLocation(result) {
      if (!result) return null;

      const placeTypeBlackList = ['country', 'region', 'postcode'];

      const filteredFeaturesList = result.features.filter(
        (feature) => placeTypeBlackList.indexOf(feature.place_type[0]) == -1
      );

      return filteredFeaturesList.map((item) => ({
        text: item.place_name,
        item,
      }));
    },

    convertItems(result) {
      if (!result) return [];
      else if (result.features && result.features.length) {
        return result.features.map((item) => ({
          text: item.place_name,
          item,
        }));
      }
    },

    handleSubmit() {
      if (this.$refs.form.validate()) {
        if (this.$vuetify.breakpoint.smAndDown) {
          this.$emit('modalClose');
        }
        this.pushTransportationSearchButtonClickAnalytics(
          this.searchParams.startLocation,
          this.searchParams.endLocation,
          this.searchParams.startTime,
          this.searchParams.passengers
        );
        this.updateSearchQuery();
        this.$emit('submitForm', this.searchParams);
      } else {
        const top = this.$refs.form.offsetTop;
        window.scrollTo(0, top);
      }
    },

    updateSearchQuery() {
      const routeName = 'transportationSearchResults';

      const query = {
        'start-location': this.searchParams.startLocation,
        'end-location': this.searchParams.endLocation,
        'start-time': this.searchParams.startTime,
        passengers: this.searchParams.passengers,
        polling: this.searchParams.polling,
        'min-price': this.searchParams.minPrice,
        'max-price': this.searchParams.maxPrice,
        currency: this.searchParams.currency,
        'vehicle-size': this.searchParams.vehicleSize,
      };

      //Condition just to catch the rejection so chrome console doesn't complain
      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        this.$router
          .replace({
            name: routeName,
            params: this.$route.params,
            query: query,
          })
          .catch(() => {});
      }
    },

    swapLocation() {
      const savedToLocationValue = this.toLocation;
      this.toLocation = this.fromLocation;
      this.fromLocation = savedToLocationValue;
    },
  },

  computed: {
    selectedDate() {
      return !!this.selected.startTime
        ? moment(this.selectedDate.startTime.split(' ')[0])
            .tz('Europe/Minsk')
            .format('ddd MMM D YYYY HH:mm:ss [GMT]ZZ (z)')
        : null;
    },

    selectedTime() {
      return !!this.selected.startTime
        ? this.reverseConvertTime(this.selected.startTime.split(' ')[1])
        : null;
    },

    // passengers() {
    //   return this.travelers;
    // },

    startTime() {
      return `${this.date} ${this.time}`;
    },

    selectedFromAddress() {
      if (!!this.fromResult.result && !!this.fromResult.result.length) {
        return this.fromResult.selectedValue || null;
      } else return null;
    },

    selectedToAddress() {
      if (!!this.toResult.result && !!this.toResult.result.length) {
        return this.toResult.selectedValue || null;
      } else return null;
    },

    fromResult() {
      return {
        result: this.hasFromSearchResult
          ? this.convertItems(this.fromSearch.result)
          : this.hasFromUserLocationResult
          ? this.convertUserLocation(this.fromSearch.userLocation)
          : [],
        isLoading: this.fromSearch.isLoading,
        selectedValue: this.fromLocation,
      };
    },

    toResult() {
      return {
        result: this.hasToSearchResult
          ? this.convertItems(this.toSearch.result)
          : this.hasToUserLocationResult
          ? this.convertUserLocation(this.toSearch.userLocation)
          : [],
        isLoading: this.toSearch.isLoading,
        selectedValue: this.toLocation,
      };
    },

    searchParams() {
      return {
        startLocation: this.selectedFromAddress || this.fromLocation,
        endLocation: this.selectedToAddress || this.toLocation,
        startTime: this.startTime,
        passengers: this.travelers,
        polling: true,
        minPrice: this.priceRange.min,
        maxPrice: this.priceRange.max,
        currency: 'USD',
        vehicleSize: this.vehicle,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.transportation-input-picker {
  max-width: 100%;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    max-width: 179px;
  }
}

.transportation-input-to-from {
  min-width: 318px;
}

::v-deep .v-input__slot {
  background: #fff !important;
}

::v-deep .transportation-input {
  .v-input__slot {
    height: 44px !important;
    border-radius: 8px;
  }

  &:not(:has(.error--text, .secondary--text)) {
    .v-input__control fieldset {
      border-color: var(--v-grey5-base);
    }
  }
}

::v-deep .transportation-label {
  @include font-size(14, 130, 600);
  margin-bottom: 5px !important;
}

::v-deep.v-input.search-input {
  .v-input__control .v-input__slot {
    background: #f8f8f8 !important;
    outline: #f8f8f8 !important;
  }
}
</style>
