import {
  TRANSPORTATION_DETAILS_ERROR,
  TRANSPORTATION_DETAILS_LOADING,
  TRANSPORTATION_DETAILS_LOADED,
  TRANSPORTATION_QUESTIONS_LOADING,
  TRANSPORTATION_QUESTIONS_ERROR,
  TRANSPORTATION_QUESTIONS_LOADED,
  TRANSPORTATION_ORDER_QUESTIONS_SET,
  ORDER_QUESTIONS_SET_TRAVELERS,
} from './mutation-types';

export const mutations = {
  [TRANSPORTATION_DETAILS_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.details = {
      ...state.details,
      value: null,
      error: error,
    };
  },
  [TRANSPORTATION_DETAILS_LOADING](state, payload = true) {
    state.details = { ...state.details, isLoading: payload };
  },
  [TRANSPORTATION_DETAILS_LOADED](
    state,
    { transportation } = { transportation: {} }
  ) {
    state.details = { ...state.details, value: { ...transportation } };
  },

  [TRANSPORTATION_QUESTIONS_LOADING](state, payload = true) {
    state.questions = { ...state.questions, isLoading: payload };
  },
  [TRANSPORTATION_QUESTIONS_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.questions = { ...state.questions, error: error };
  },
  [TRANSPORTATION_QUESTIONS_LOADED](state, { questions } = { questions: {} }) {
    if (state.orderInformation.answers != null) {
      for (let i in questions) {
        for (let j in questions[i].questions) {
          let id = questions[i].questions[j].id;
          let question = questions[i].questions[j];
          questions[i].questions[j] = {
            ...question,
            value: state.orderInformation.answers[id],
          };
        }
      }
    }

    state.questions = { ...state.questions, value: { ...questions } };
  },
  [TRANSPORTATION_ORDER_QUESTIONS_SET](state, { answers } = { answers: null }) {
    state.orderInformation = {
      ...state.orderInformation,
      answers,
    };
  },
  [ORDER_QUESTIONS_SET_TRAVELERS](state, travelers) {
    state.travelers = travelers;
  },
};
