<template>
  <v-container fluid id="QuantityField" class="pa-0">
    <v-row
      no-gutters
      :class="[
        'd-flex quantity-title',
        { 'pt-2': !!subTitle && !!valueSupportText },
      ]"
    >
      <v-col cols="auto">
        {{ title }}
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="auto">
        <v-btn-toggle class="input-wrapper">
          <v-btn
            type="button"
            class="decrement-button pa-0 button-col"
            @click="decrementValue()"
            outlined
            icon
            :disabled="!canDecrementValue"
            :color="colored ? 'primary' : undefined"
            :aria-label="$t('transportation.search.quantity.down_button')"
            :title="$t('transportation.search.quantity.down_button')"
          >
            <v-icon aria-hidden="true">mdi-minus</v-icon>
          </v-btn>

          <v-text-field
            class="font-weight-black title num-val pa-0 d-flex align-center"
            v-model.number="currentValue"
            @blur="validateValue()"
            @click="clearInput()"
            maxlength="2"
            v-on:keypress="onKeyPress($event)"
            dense
            solo
            flat
            hide-details
            :disabled="!canEditTextField"
            label=""
            aria-labelledby="QuantityField"
            aria-selected="false"
            tabindex="0"
          />

          <v-btn
            type="button"
            class="increment-button pa-0 button-col"
            @click="incrementValue()"
            data-cy="increment-pass"
            outlined
            :disabled="!canIncrementValue"
            :color="colored ? 'primary' : undefined"
            :aria-label="$t('transportation.search.quantity.up_button')"
            :title="$t('transportation.search.quantity.up_button')"
          >
            <v-icon aria-hidden="true">mdi-plus</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <template v-if="!!valueSupportText || !!subTitle">
      <v-row
        no-gutters
        :class="['pt-1', { 'justify-end': !!valueSupportText }]"
      >
        <v-col cols="auto">
          <v-card-subtitle class="pa-0 quantity-subtitle">
            <template v-if="!!subTitle">
              {{ subTitle }}
            </template>

            <template v-if="!!valueSupportText">
              {{ valueSupportText }}
            </template>
          </v-card-subtitle>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
export default {
  name: 'QuantityField',
  props: {
    title: String,
    subTitle: String,
    value: { type: Number, default: 0 },
    valueSupportText: String,
    max: { type: Number, default: Number.MAX_VALUE },
    min: { type: Number, default: 0 },
    colored: { type: Boolean, default: false },
  },

  data: function() {
    return {
      currentValue: this.value,
    };
  },

  methods: {
    incrementValue() {
      this.setCurrentValueIfUnset();
      this.currentValue = parseInt(this.currentValue, 10) + 1;
    },

    decrementValue() {
      this.setCurrentValueIfUnset();
      if (this.currentValue > 0 && this.currentValue) {
        this.currentValue = parseInt(this.currentValue, 10) - 1;
      }
    },

    setCurrentValueIfUnset() {
      if (isNaN(parseFloat(this.currentValue)) || this.currentValue < 0) {
        this.currentValue = 0;
      }
    },

    validateValue() {
      this.setCurrentValueIfUnset();
      this.validateMax();
      this.validateMin();
    },

    validateMax() {
      if (!!this.max && this.currentValue > this.max) {
        this.currentValue = this.max;
      }
    },

    validateMin() {
      if (!!this.min && this.currentValue < this.min) {
        this.currentValue = this.min;
      }
    },

    clearInput() {
      this.currentValue = null;
    },

    onKeyPress(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      //Check that the key pressed is numerical
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else {
        this.setCurrentValueIfUnset();
        //Check that the key pressed is enter or return key
        if (charCode == 13) {
          this.validateValue();
        }
        this.currentValue = parseInt(this.currentValue, 10);
        return true;
      }
    },
  },

  watch: {
    currentValue() {
      this.$emit('input', this.currentValue);
    },

    value(value) {
      this.currentValue = value;
    },

    max(max) {
      if (max === 0) this.currentValue = 0;
    },
  },

  computed: {
    canIncrementValue() {
      return isNaN(this.max) || this.currentValue < this.max;
    },

    canDecrementValue() {
      return isNaN(this.min) || this.currentValue > this.min;
    },

    canEditTextField() {
      return this.canIncrementValue || this.canDecrementValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-row {
  max-height: 66px;
}

.quantity-title {
  @include font-size(14, 130, 600);
  margin-bottom: 5px;
}

.quantity-subtitle {
  @include font-size(14, 20);
}

.input-wrapper {
  border: 1px solid var(--v-grey4-base);
  height: 45px !important;
  border-radius: 8px;
}

.button-col {
  height: 44px !important;
  border: none !important;
}

.decrement-button {
  border-right: 1px solid var(--v-grey4-base) !important;
  border-radius: 8px 0 0 8px !important;
}

.increment-button {
  border-left: 1px solid var(--v-grey4-base) !important;
  border-radius: 0 8px 8px 0 !important;
}

::v-deep.v-input.num-val {
  .v-input__control .v-input__slot {
    background: transparent !important;

    input {
      text-align: center;
    }
  }
}
</style>
