<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col cols="12" class="pb-5">
        <v-divider />
      </v-col>

      <template v-if="hasRides">
        <v-col
          cols="12"
          md="4"
          class="d-flex align-center"
          :class="{ 'mb-2 pt-2 px-3': $vuetify.breakpoint.mdAndDown }"
        >
          <template v-if="allRides.total > 0">
            <span
              :class="[
                'text-capitalize result-title',
                { 'pb-4': $vuetify.breakpoint.smAndDown },
              ]"
            >
              {{ allRides.total }}
              {{ $t('transportation.results_found') }}
            </span>
          </template>
        </v-col>

        <v-col :class="{ 'px-3': $vuetify.breakpoint.mdAndDown }">
          <v-row no-gutters class="justify-start justify-md-end">
            <v-col class="pr-2 pl-0 mb-2" cols="auto">
              <PriceRangePopoverModal
                @submit="onPriceRangeSubmit"
                :baseCurrency="currency"
                :min="minPrice"
                :max="maxPrice"
              />
            </v-col>

            <v-col class="mb-2" cols="auto">
              <VehicleTypesPopoverModal
                @submit="onVehicleSubmit"
                :vehicleSize="vehicleSize"
              />
            </v-col>
          </v-row>
        </v-col>
      </template>

      <v-col cols="12" class="text-center">
        <template v-if="isLoadingRides">
          <v-progress-circular
            indeterminate
            color="primary lighten-1"
            :size="53"
          />
          <p class="loader-banner-title py-10">
            {{ $t('transportation.banner.question') }}
          </p>
          <p>
            {{ $t('transportation.banner.text1') }}
          </p>
          <p>
            {{ $t('transportation.banner.text2') }}
          </p>
        </template>

        <template v-if="noresults">
          <v-img
            :alt="$t('transportation.no_search_results')"
            contain
            height="232px"
            width="200px"
            src="@/assets/noresults.png"
            position="center"
            class="mx-auto"
          />
          <p class="mb-0 mx-auto">
            {{ $t('transportation.filters.adjusting_filter1') }}
          </p>
          <p class="mb-0 mx-auto">
            {{ $t('transportation.filters.adjusting_filter2') }}
          </p>
        </template>

        <template v-if="allRides.error && !hasRides">
          <div class="error-message-banner">
            <v-img
              alt="errorImage"
              contain
              height="200"
              width="200"
              :src="errorImage"
              class="mx-auto"
            />
            <p class="error-message-banner__title mb-4 mx-auto">
              {{ returnErrorMessage }}
            </p>
            <p class="error-message-banner__text mb-0 mx-auto">
              {{ $t('transportation.error_message') }}
            </p>
          </div>
        </template>
      </v-col>
      <template v-if="hasRides">
        <v-col cols="12">
          <ResultListComponent
            :rideList="allRides.value"
            :searchLocationQuery="searchLocationQuery"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import ResultListComponent from './features/Results/ResultListComponent';
import GroundTransportationMixins from './mixins/GroundTransportationMixins';
import PriceRangePopoverModal from '@/modules/common/filters/PriceRangePopoverModal';
import VehicleTypesPopoverModal from '@/modules/common/filters/VehicleTypesPopoverModal';
import arraysUtils from '@/core/utils/arrays';

export default {
  name: 'transportation-result-page',
  mixins: [GroundTransportationMixins],
  components: {
    PriceRangePopoverModal,
    VehicleTypesPopoverModal,
    ResultListComponent,
  },

  props: {
    passengers: Number,
    startLocation: String,
    endLocation: String,
    startTime: String,
    polling: Boolean,
    minPrice: Number,
    maxPrice: Number,
    currency: String,
    vehicleSize: [String, Array],
  },

  data() {
    return {
      errorImage: require('@/assets/error-image.svg'),
      headerTitle: this.$t('transportation.your_ride'),
      resetTimeoutId: null,
      openRefreshModal: false,
      refreshTimeoutDuration: {
        minutes: 15,
      },
      formDisplay: false,
      priceRange: {
        min: this.minPrice || 1,
        max: this.maxPrice || 10000,
      },
      vehicle: this.selectedVehicleSize || null,
    };
  },

  created() {
    if (!this.hasPreviousSearchResults) {
      this.updateSearchContent(this);
    }
  },

  computed: {
    noresults() {
      return !this.hasRides && !this.isLoadingRides && !this.allRides.error;
    },

    returnErrorMessage() {
      if (this.allRides.error.includes('400')) {
        return this.$t('transportation.bad_request');
      } else if (this.allRides.error.includes('500')) {
        return this.$t('transportation.server_error');
      } else {
        return this.$t('transportation.something_wrong');
      }
    },

    matchedSearchResultsParams() {
      const {
        startLocation,
        endLocation,
        startTime,
        passengers,
        polling,
        minPrice,
        maxPrice,
        currency,
        vehicleSize,
      } = this.allRides;
      return (
        startLocation === this.startLocation &&
        endLocation === this.endLocation &&
        startTime === this.startTime &&
        passengers === this.passengers &&
        polling === this.polling &&
        minPrice === this.minPrice &&
        maxPrice === this.maxPrice &&
        currency === this.currency &&
        arraysUtils.equals(vehicleSize, this.vehicleSize)
      );
    },

    hasPreviousSearchResults() {
      return this.hasRides && this.matchedSearchResultsParams;
    },

    currentSelectedParams() {
      return {
        startLocation: this.startLocation,
        endLocation: this.endLocation,
        startTime: this.startTime,
        passengers: this.passengers,
        polling: this.polling,
        minPrice: this.priceRange.min,
        maxPrice: this.priceRange.max,
        currency: this.currency,
        vehicleSize: this.vehicle,
      };
    },

    searchLocationQuery() {
      return `${this.startLocation} - ${this.endLocation}`;
    },
  },

  methods: {
    onVehicleSubmit(val) {
      this.vehicle = val;
    },

    onPriceRangeSubmit([min, max]) {
      this.priceRange.min = min;
      this.priceRange.max = max;
    },

    updateSearchQuery() {
      const routeName = 'transportationSearchResults';
      this.$router.push({
        name: routeName,
        params: this.$route.params,
        query: {
          'start-location': this.startLocation,
          'end-location': this.endLocation,
          'start-time': this.startTime,
          passengers: this.passengers,
          polling: this.polling,
          'min-price': this.priceRange.min,
          'max-price': this.priceRange.max,
          currency: this.currency,
          'vehicle-size': this.vehicle,
        },
      });
    },

    updateSearchContent(payload) {
      const {
        startLocation,
        endLocation,
        startTime,
        passengers,
        polling,
        minPrice,
        maxPrice,
        currency,
        vehicleSize,
      } = payload;
      this.searchRides({
        startLocation,
        endLocation,
        startTime,
        passengers,
        polling,
        minPrice,
        maxPrice,
        currency,
        vehicleSize,
      });
    },

    async refreshResults() {
      this.openRefreshModal = true;
      await this.clearRides();
      this.updateSearchContent(this);
    },

    setRefreshTimer(searchTimestamp) {
      const startTime = moment(searchTimestamp);
      const nowTime = moment();
      const finishTime = startTime.add(this.refreshTimeoutDuration);
      const diffDurationTime = finishTime.diff(nowTime, 'ms'); // now to finishTime in miliseconds

      this.resetTimeoutId = setTimeout(() => {
        this.refreshResults();
      }, diffDurationTime);
    },

    clearRefreshTimer() {
      if (this.resetTimeoutId) {
        clearTimeout(this.resetTimeoutId);
      }
    },

    toggleFormDisplay() {
      this.formDisplay = this.formDisplay ? false : true;
    },

    toArray(item) {
      return Array.isArray(item) ? item : new Array(item);
    },
  },

  watch: {
    currentSelectedParams: {
      deep: true,
      handler(currentSelectedParams) {
        this.updateSearchQuery();
        this.updateSearchContent(currentSelectedParams);
      },
    },
  },

  beforeDestroy() {
    this.clearRefreshTimer();
  },
};
</script>

<style lang="scss" scoped>
.center-align {
  text-align: center;
}

.loader-banner-title {
  font-weight: 600;
  font-size: 24px;
}

.no-result-text {
  font-size: 16px;
}

.fill-width {
  width: 100%;
}

.divider {
  color: #cfd5e0;
  height: 1px;
}

.form-header {
  @include font-size(24, 32, 600);
  color: #05090f;
}

.no-ride {
  display: inline;
}

.center-child {
  width: 100%;
  text-align: center;
}

.search-menu {
  background: #fff;
  font-weight: 600;
  font-size: 16px;
}

.result-title {
  @include font-size(20, 150, 600);
  display: flex;
  align-items: center;
  color: #05090f;
}

::v-deep .error-message-banner {
  &__text {
    max-width: 347px;
    text-align: center;
    @include font-size(16, 24);
  }

  &__title {
    @include font-size(18, 26, 600);
  }
}
</style>
