<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="420"
      content-class="gt-refresh-modal"
    >
      <v-card>
        <v-container class="pt-4">
          <v-row justify="center">
            <v-col cols="auto">
              <CircularTimer :timestamp="timestamp"></CircularTimer
            ></v-col>
          </v-row>
          <v-row justify="center" no-gutters>
            <v-col cols="auto">
              <p class="title text-center">
                {{ $t('transportation.warning_modal.complete_booking') }}
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-col cols="auto">
              <p class="px-3 text-center">
                {{ $t('transportation.warning_modal.order_message') }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn block outlined large color="primary" @click="onContinue">
                {{ $t('transportation.warning_modal.continue_browsing') }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn block large depressed color="primary" @click="onBookRide">
                {{ $t('transportation.warning_modal.book_ride_now') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CircularTimer from '@/core/components/CircularTimer';

export default {
  name: 'TimeoutWarningModal',
  components: { CircularTimer },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    timestamp: {
      type: Number,
      default: new Date(Date.now()).getTime(),
    },
  },

  data() {
    return {
      dialog: this.open,
      timePassed: 0,
      timerInterval: null,
    };
  },

  watch: {
    open: {
      immediate: true,
      handler(open) {
        this.dialog = open;
      },
    },
  },

  methods: {
    onContinue() {
      this.$emit('close');
    },

    onBookRide() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.gt-refresh-modal .v-card.v-sheet {
  border-radius: 8px;
}

.title {
  @include font-size(18, 26, 600);
}
</style>
