<template>
  <v-dialog fullscreen hide-overlay :value="dialog">
    <v-card>
      <v-container class="px-5 py-8">
        <v-row no-gutters class="pb-6">
          <v-col cols="12" class="d-flex justify-space-between">
            <span class="title">
              {{ $t('transportation.header_title') }}
            </span>
            <span @click="onClose">
              <v-icon> mdi-close </v-icon>
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="auto">
            <SearchContainer :selected="selected" @modalClose="onClose" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchContainer from './SearchContainer';

export default {
  name: 'SearchModalComponent',
  components: { SearchContainer },

  props: {
    modalView: Boolean,
    selected: Object,
  },

  data() {
    return {
      dialog: this.modalView,
    };
  },

  methods: {
    onClose() {
      this.$emit('close');
      this.dialog = false;
    },
  },

  watch: {
    modalView(val) {
      this.dialog = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @include font-size(18, 166.6668, 600);
  color: #05090f;
}
</style>
