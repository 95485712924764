export const state = () => ({
  details: {
    value: null,
    error: null,
    isLoading: false,
  },
  questions: {
    value: null,
    error: null,
    isLoading: false,
  },
  orderInformation: {
    answers: null,
  },
  travelers: [],
});
